import { Application } from "@hotwired/stimulus";
const application = Application.start();

import AcceptTurboStreamController from "./accept_turbo_stream_controller";
application.register("accept-turbo-stream", AcceptTurboStreamController);

import Admin__AiSearchController from "./admin/ai_search_controller";
application.register("admin--ai-search", Admin__AiSearchController);

import Admin__AppSelectorController from "./admin/app_selector_controller";
application.register("admin--app-selector", Admin__AppSelectorController);

import Admin__LocalizedFieldController from "./admin/localized_field_controller";
application.register("admin--localized-field", Admin__LocalizedFieldController);

import Admin__PreviewController from "./admin/preview_controller";
application.register("admin--preview", Admin__PreviewController);

import Admin__SelectLessonController from "./admin/select_lesson_controller";
application.register("admin--select-lesson", Admin__SelectLessonController);

import AnimationController from "./animation_controller";
application.register("animation", AnimationController);

import AudioPlayerController from "./audio_player_controller";
application.register("audio-player", AudioPlayerController);

import AutocompleteController from "./autocomplete_controller";
application.register("autocomplete", AutocompleteController);

import ButtonColorController from "./button_color_controller";
application.register("button-color", ButtonColorController);

import CarouselController from "./carousel_controller";
application.register("carousel", CarouselController);

import CircleController from "./circle_controller";
application.register("circle", CircleController);

import ConnectedDispatcherController from "./connected_dispatcher_controller";
application.register("connected-dispatcher", ConnectedDispatcherController);

import CountableController from "./countable_controller";
application.register("countable", CountableController);

import CountdownController from "./countdown_controller";
application.register("countdown", CountdownController);

import Blog__MenuController from "./blog/menu_controller";
application.register("blog--menu", Blog__MenuController);

import Customer__AboutMoveAnimationController from "./customer/about_move_animation_controller";
application.register(
  "customer--about-move-animation",
  Customer__AboutMoveAnimationController
);

import Customer__AccountsController from "./customer/accounts_controller";
application.register("customer--accounts", Customer__AccountsController);

import Customer__AutoclickController from "./customer/autoclick_controller";
application.register("customer--autoclick", Customer__AutoclickController);

import Customer__BannerAnimationController from "./customer/banner_animation_controller";
application.register(
  "customer--banner-animation",
  Customer__BannerAnimationController
);

import Customer__BodyAccordionController from "./customer/body_accordion_controller";
application.register(
  "customer--body-accordion",
  Customer__BodyAccordionController
);

import Customer__CourseLessonsController from "./customer/course_lessons_controller";
application.register(
  "customer--course-lessons",
  Customer__CourseLessonsController
);

import Customer__EmailValidationController from "./customer/email_validation_controller";
application.register(
  "customer--email-validation",
  Customer__EmailValidationController
);

import Customer__FirebaseNotificationRequestController from "./customer/firebase_notification_request_controller";
application.register(
  "customer--firebase-notification-request",
  Customer__FirebaseNotificationRequestController
);

import Customer__FormQuizController from "./customer/form_quiz_controller";
application.register("customer--form-quiz", Customer__FormQuizController);

import Customer__HomeAnimationController from "./customer/home_animation_controller";
application.register(
  "customer--home-animation",
  Customer__HomeAnimationController
);

import Customer__HomeBannerAnimationController from "./customer/home_banner_animation_controller";
application.register(
  "customer--home-banner-animation",
  Customer__HomeBannerAnimationController
);

import Customer__HomeMoveAnimationController from "./customer/home_move_animation_controller";
application.register(
  "customer--home-move-animation",
  Customer__HomeMoveAnimationController
);

import Customer__InputOtpController from "./customer/input_otp_controller";
application.register("customer--input-otp", Customer__InputOtpController);

import Customer__InputValidationController from "./customer/input_validation_controller";
application.register(
  "customer--input-validation",
  Customer__InputValidationController
);

import Customer__LanguageSelectorController from "./customer/language_selector_controller";
application.register(
  "customer--language-selector",
  Customer__LanguageSelectorController
);

import Customer__LessonFeedbackController from "./customer/lesson_feedback_controller";
application.register(
  "customer--lesson-feedback",
  Customer__LessonFeedbackController
);

import Customer__ModalController from "./customer/modal_controller";
application.register("customer--modal", Customer__ModalController);

import Customer__NotificationTemplateFormController from "./customer/notification_template_form_controller";
application.register(
  "customer--notification-template-form",
  Customer__NotificationTemplateFormController
);

import Customer__PageHeaderController from "./customer/page_header_controller";
application.register("customer--page-header", Customer__PageHeaderController);

import Customer__PageLoaderController from "./customer/page_loader_controller";
application.register("customer--page-loader", Customer__PageLoaderController);

import Customer__PhoneValidationController from "./customer/phone_validation_controller";
application.register(
  "customer--phone-validation",
  Customer__PhoneValidationController
);

import Customer__QuizController from "./customer/quiz_controller";
application.register("customer--quiz", Customer__QuizController);

import Customer__QuizResultsController from "./customer/quiz_results_controller";
application.register("customer--quiz-results", Customer__QuizResultsController);

import Customer__RecaptchaController from "./customer/recaptcha_controller";
application.register("customer--recaptcha", Customer__RecaptchaController);

import Customer__SubscribeController from "./customer/subscribe_controller";
application.register("customer--subscribe", Customer__SubscribeController);

import Customer__SubscriptionModalController from "./customer/subscription_modal_controller";
application.register(
  "customer--subscription-modal",
  Customer__SubscriptionModalController
);

import Customer__ToastController from "./customer/toast_controller";
application.register("customer--toast", Customer__ToastController);

import Customer__YouMobileBtnController from "./customer/you_mobile_btn_controller";
application.register(
  "customer--you-mobile-btn",
  Customer__YouMobileBtnController
);

import CharacterCounterController from "./character_counter_controller";
application.register("character-counter", CharacterCounterController);

import FilterableController from "./filterable_controller";
application.register("filterable", FilterableController);

import FormController from "./form_controller";
application.register("form", FormController);

import FormMultipleController from "./form_multiple_controller";
application.register("form-multiple", FormMultipleController);

import HomePlayerController from "./home_player_controller";
application.register("home-player", HomePlayerController);

import LessonAudioPlayerController from "./lesson_audio_player_controller";
application.register("lesson-audio-player", LessonAudioPlayerController);

import MixpanelController from "./mixpanel_controller";
application.register("mixpanel", MixpanelController);

import MixpanelVivaController from "./mixpanel_viva_controller";
application.register("mixpanel-viva", MixpanelVivaController);

import NestedFormController from "./nested_form_controller";
application.register("nested-form", NestedFormController);

import PhoneController from "./phone_controller";
application.register("phone", PhoneController);

import ProgramsController from "./programs_controller";
application.register("programs", ProgramsController);

import ProviderController from "./provider_controller";
application.register("provider", ProviderController);

import ResetFormController from "./reset_form_controller";
application.register("reset-form", ResetFormController);

import RevealController from "./reveal_controller";
application.register("reveal", RevealController);

import SignupController from "./signup_controller";
application.register("signup", SignupController);

import SortableController from "./sortable_controller";
application.register("sortable", SortableController);

import StripeController from "./stripe_controller";
application.register("stripe", StripeController);

import VerificationCountdownController from "./verification_countdown_controller";
application.register("verification-countdown", VerificationCountdownController);
