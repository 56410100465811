import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["localizedElement"];

  connect() {
    this.#toggleLanguage('es');
  }

  changeLanguage(event) {
    const language = event.target.value;
    this.#toggleLanguage(language);
  }

  #toggleLanguage(language) {
    this.localizedElementTargets.forEach((element) => {
      const isVisible = element.dataset.language === language;
      element.classList.toggle("hidden", !isVisible);
    });
  }
}
